import { AxiosResponse } from 'axios';
import { Country, Language, PortalTab, SentAccess } from '@/types';
import { client } from '@/client';

export type GdprForm = {
  id: number;
  formName: string;
  email: string;
  autodeleteCountDays?: number;
  country: Country;
};

export type CountryConfiguration = {
  teleidMaxUsersNumber: number;
  telesignMaxUsersNumber: number;
  safemailMaxUsersNumber: number;
  teleidEnabled: boolean;
  teleidSsnEnabled: boolean;
  teleidSsnNorwayEnabled: boolean;
  teleidEmailEnabled: boolean;
  teleidSmsEnabled: boolean;
  teleidChatEnabled: boolean;
  telesignEnabled: boolean;
  telesignSsnEnabled: boolean;
  telesignEmailEnabled: boolean;
  telesignSmsEnabled: boolean;
  telesignChatEnabled: boolean;
  safemailEnabled: boolean;
  safemailEmailEnabled: boolean;
  safemailSmsEnabled: boolean;
  country: Country;
  teleidCounter: number;
  telesignCounter: number;
  safemailCounter: number;
  defaultLanguage?: Language;
};

export enum EncryptionLevel {
  NONE = 0,
  SINGLE = 1,
  DOUBLE = 2,
}

export type Company = {
  apiClientIp: string | null;
  apiEnabled: boolean;
  autodeleteCountDays: number;
  code: string;
  companyLogoUrl: string | null;
  name: string;
  companysignatory: boolean;
  countries: CountryConfiguration[];
  defaultBankIdCountry: Country | null;
  defaultStartTab: PortalTab | null;
  deleted: boolean;
  email: string;
  encryptionLevel: EncryptionLevel;
  forms: GdprForm[];
  formIdOnlySafemail: boolean;
  id: number;
  intenttext: string | null;
  referenceTextDenmark: string | null;
  keyStore: string;
  keyStorePassword: string | null;
  messageFooter: string | null;
  requestSignatureEnabled: boolean;
  fullSsnFilenameSafemailPdfzip: boolean;
  sentAccess: SentAccess;
  telesignDefaultText: string | null;
  telesignDefaultTextDisabled: boolean;
  timezone: string | null;
  usernamePasswordNewAccountsDisabled: boolean;
  suspended: boolean;
  apiKey: string | null;
  apiToken: string | null;
};

export type TokenResponse = {
  token: string;
};

export async function getCompanyConfig(
  // The token can be passed as an argument in order for this function to be callable before the token is persisted.
  token?: string,
  signal?: AbortSignal,
): Promise<Company> {
  if (token) {
    return client
      .get<Company>('/company/config', {
        headers: {
          Authorization: `Basic ${window.btoa(`${token}:${token}`)}`,
        },
        signal,
      })
      .then((response) => response.data);
  }

  return client
    .get<Company>('/company/config')
    .then((response) => response.data);
}

export async function generateApiToken(
  signal?: AbortSignal,
): Promise<TokenResponse> {
  return client
    .post<
      never,
      AxiosResponse<TokenResponse>
    >('/company/token', null, { signal })
    .then((response) => response.data);
}
